<template>
  <Transition>
    <div
      v-if="homeHeader && homeHeader !== '<br>'"
      class="top-banner p-3"
      :style="{ backgroundColor: bgColor, color: color }"
    >
      <span v-html="homeHeader" />
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mainStore } from '@/shared/pinia-store/main';

@Options({
  name: 'TopBanner',
  components: {},
})
export default class TopBanner extends Vue {
  @Prop({ default: () => 'homeHeader' }) public bannerName!: string;
  @Prop({ default: () => '', required: false }) bgColor!: string;
  @Prop({ default: () => 'white', required: false }) color!: string;

  public get siteMessageByName() {
    const { siteMessageByName } = mainStore();
    return siteMessageByName.value;
  }

  public getSiteMessageByName = mainStore().getSiteMessageByName;

  public get homeHeader() {
    try {
      return this.siteMessageByName(this.bannerName);
    } catch (_e) {
      return null;
    }
  }

  public get characterOfTheDay() {
    return {
      slug: '',
      name: '',
    };
  }

  public get user() {
    return {
      name: '',
    };
  }

  public async fetchSiteMessageByName() {
    try {
      const data = await this.getSiteMessageByName(this.bannerName);
      if (data?.message) {
        this.$emit('loaded', data.message);
        return data;
      }
    } catch (e) {
      // Use if needed.
    }
  }

  public async created() {
    this.fetchSiteMessageByName();
  }
}
</script>

<style lang="sass" scoped>
.top-banner
  background-color: #A7AABE
  color: #FFF
  font-size: 12px
  border-radius: 4px
  .link-color
    color: #e3a7ff

.v-enter-active,
.v-leave-active
  transition: opacity 1s ease

.v-enter-from,
.v-leave-to
  opacity: 0
</style>
